/** 首页路由 */
export default[
    {
        path:'money',
        meta: {title: '营收'},
        name:'index',
        component: ()=>import('@/pages/money/index.vue')
    }
]


