<template>
  <div>
    <Modal
        title="修改密码"
        v-model="showModel"
        width="600">
      <Form :model="formItem" :label-width="120" style="width: 500px">
        <FormItem label="当前密码">
          <Input v-model="formItem.old" placeholder="请输入当前密码"></Input>
        </FormItem>
        <FormItem label="新密码">
          <Input v-model="formItem.new" placeholder="请输入新密码"></Input>
        </FormItem>
        <FormItem label="确认新密码">
          <Input v-model="formItem.moreNew" placeholder="请确认新密码"></Input>
        </FormItem>
      </Form>
    </Modal>
  </div>
</template>

<script>
export default {
  name: "password",
  data(){
    return{
      formItem:{
        old:'',
        new:'',
        moreNew:''
      },
      showModel:false
    }
  },
  methods:{
    open(e){
      this.showModel=true
      console.log(e)
    }
  }
}
</script>

<style scoped>

</style>