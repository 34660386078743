import axios from 'axios';
import { Message } from 'view-design';
import config from '@/config';
import router from '@/router'
import { getCookies, removeCookies } from '@/utils/helper'
import store from '../store';

const service = axios.create({
    baseURL: config.apiUrl + '/storeapi',
    timeout: 100000 // 请求超时时间
})
store.commit("setPop_status", false);
service.interceptors.request.use(
    config => {
        if (config.url == '/apply/getWxConf' || config.url == '/apply/getAuthStatus' || config.url == '/apply/getWxApproveStatus' || (typeof config.data != 'undefined' && typeof config.data.no_popup != 'undefined')) {
            console.log('不弹窗')
            store.commit("setPop_status", false);
        } else {
            store.commit("setPop_status", true);
        }
        const token = getCookies('hlzw_canyin_token')
        // const token = "ec662137512c6252d67e2b5fb4d5e3d7"
        if (token) {
            config.headers['hlzw-canyin-authori-zation'] = token
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
)

service.interceptors.response.use(
    response => {
        let status = response.data ? response.data.code : 0
        const code = Number(status)
        store.commit("setPop_status", false);
        switch (code) {
            case 200:
                return response.data
            case 401:
            case 400:
                return Promise.reject(response.data || { msg: '未知错误' })
            case 400011:
            case 500:
            case 400012:
                return Promise.reject({ msg: '系统异常' })
            case 410000:
            case 410001:
            case 410002:
                localStorage.clear()
                removeCookies('hlzw_canyin_token')
                removeCookies('Hlzw-Oss-Token')
                removeCookies("Hlzw-Oss-Data-Id")
                removeCookies('Hlzw-Oss-Login-Token')
                router.replace({ path: '/' })
                break
            default:
                break
        }
    },
    error => {
        store.commit("setPop_status", false);
        if (typeof error.response == "undefined") {
            Message.error("服务器异常");
        }
        return Promise.reject(error);
    }

)

export default service;
