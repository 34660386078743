import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate'
Vue.use(Vuex);

export default new Vuex.Store({
    state:{
        userInfo:{},
        menu:[],
        plugin:[],
        order_untreated:{},
        activeIndex:0,
        pop_status:false,
        store_mode:null,
        pay_url:[]
    },
    mutations:{
        setStore_mode(state, store_mode){
            state.store_mode = store_mode
        },
        setConfg_Pay_url(state, pay_url){
            state.pay_url = pay_url
        },
        setPop_status(state, pop_status){
            state.pop_status = pop_status
        },
        setUserInfo(state, userInfo){
            state.userInfo = userInfo
        },
        setOrderUntreated(state, order_untreated){
            state.order_untreated = order_untreated
        },
        setMenu(state, menu){
            state.menu = menu
        },
        setPlugin(state, plugin){
            state.plugin = plugin
        },
        setActiveIndex(state, activeIndex){
            state.activeIndex = activeIndex
        },
        reset(state){
            state.userInfo = {};
        }
    },
    getters:{

    },
    actions:{
        resetAction({ commit }){
            commit('reset')
        }
    },
    modules:{
        
    },
    plugins: [createPersistedState()]
})