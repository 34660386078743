/** 首页路由 */
export default[
    {
        path:'user',
        meta: {title: '用户'},
        name:'index',
        component: ()=>import('@/pages/user/index.vue')
    }
]


