import Vue from "vue";
import VueRouter from "vue-router";
import mainLayout from "@/components/mainLayout"
import index from "./modules/index"
import customer from "./modules/customer"
import setting from "./modules/setting"
import money from "./modules/money"
import user from "./modules/user"
import order from "./modules/order"
Vue.use(VueRouter);
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
    routes: [
        {
            name: '登录',
            path: '/',
            component: () => import("@/pages/login/login.vue")
        },
        {
            path: '/admin',
            meta: { title: '互联餐饮系统' },
            component: mainLayout,
            children: [
                ...index,
                ...order,
                ...money,
                ...customer,
                ...user,
                ...setting
            ]
        },
    ]
})

router.beforeEach((to, from, next) => {
    window.document.title = "互联餐饮系统";
    next();
})
export default router;
