<template>
  <div id="app" class="layout">
    
    <router-view />
    <div v-if="pop_status" class="pop_model">
      <img src="../src/assets/loading.gif" alt="">
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
    };
  },
  computed:{
    pop_status(){
      return this.$store.state.pop_status
    },
  },
  mounted() {
  },
  methods: {
  },
};
</script>


<style>
@import "assets/css/iconfont.css";
.layout {
  overflow: hidden !important;
}
.vertical-center-modal {
  display: flex;
  align-items: center;
  justify-content: center;
}
/*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background-color: #f5f5f5;
}

/*定义滚动条轨道 内阴影+圆角*/
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

/*定义滑块 内阴影+圆角*/
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #555;
}
.ml10{
    margin-left: 10px;
    margin-top: -2px;
}
.pointer{
  cursor: pointer;
}
.pop_model{
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 99999;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pop_model img{
  width: 100px;
  height: 100px;
}
</style>
