import Vue from 'vue'
import App from './App.vue'
import router from './router'
import config from '@/config';
import ViewUI from 'view-design';
import store from './store'
import 'view-design/dist/styles/iview.css';
import BaiduMap from 'vue-baidu-map'
import Moment from 'moment'
import 'swiper/dist/css/swiper.css'
Vue.use(BaiduMap, {
// ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
  ak: 'YOUR_APP_KEY'
})

Vue.use(Moment);
Vue.use(ViewUI);

Vue.config.productionTip = false
Vue.prototype.config = config
new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')
