import request from '@/utils/request'

export function orderList (data) {
    return request({
        url: '/order/orderList',
        method: 'post',
        data
    })
}

export function handle (data) {
    return request({
        url: '/order/handle',
        method: 'post',
        data
    })
}

export function orderRecord (data) {
    return request({
        url: '/order/orderRecord',
        method: 'post',
        data
    })
}

export function getUntreated () {
    let data = {no_popup:1};
    return request({
        url: '/order/getUntreated',
        method: 'post',
        data
    })
}

export function orderPrint (data) {
    return request({
        url: '/order/orderPrint',
        method: 'post',
        data
    })
}

export function getOrderPrintContent(data){
    return request({
        url: '/order/getPrintData',
        method: 'post',
        data
    })
}

export function tsOrderList(data){
    return request({
        url: '/order/tsOrderList',
        method: 'post',
        data
    })
}

export function getTsOrderBranch(data){
    return request({
        url: '/order/getTsOrderBranch',
        method: 'post',
        data
    })
}

export function tsOrderHandle(data){
    return request({
        url: '/order/tsOrderHandle',
        method: 'post',
        data
    })
}

export function getPrint(data){
    return request({
        url: '/cash/getPrint',
        method: 'post',
        data
    })
}

export function printOrder(data){
    return request({
        url: '/order/printOrder',
        method: 'post',
        data
    })
}

export function orderCancelHandle(data){
    return request({
        url: '/order/orderCancelHandle',
        method: 'post',
        data
    })
}

export function recordDetails(data){
    return request({
        url: '/order/recordDetails',
        method: 'post',
        data
    })
}

export function plusPrint(data){
    return request({
        url: '/order/plusPrint',
        method: 'post',
        data
    })
}

export function getUsbOrderData(data){
    return request({
        url: '/order/getUsbOrderData',
        method: 'post',
        data
    })
}
export function delUsbPrint(data){
    return request({
        url: '/order/delUsbPrint',
        method: 'post',
        data
    })
}

export function orderWriteOff(data){
    return request({
        url: '/order/orderWriteOff',
        method: 'post',
        data
    })
}

export function getTakeCodeList(data){
    return request({
        url: '/order/getTakeCodeList',
        method: 'post',
        data
    })
}