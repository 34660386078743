export default {
    title: '餐饮',
    baserUrl: 'https://wxapplets.puhuijishu.top',// 小程序上传域名
    wsUrl: 'wss://canyin.hulianzaowu.top:6001',
    // wsUrl:'wss://cy.gongzuobaogao.cn:6001',
    // wsUrl:`wss://${location.hostname}:6001`,
    // ossUrl: 'http://192.168.1.199:9091', // oss上传域名
    ossUrl: 'https://oss.hulianzaowu.top', // oss上传线上域名
    // apiUrl:'https://cy.gongzuobaogao.cn', // 测试域名
    // apiUrl: 'https://canyin.hulianzaowu.top', // 线上域名
    // apiUrl:'http://www.cy.com', // 本地域名
    // apiUrl: 'http://192.168.1.199:90', // 本地域名
    apiUrl : `${location.origin}` // 自动获取
}