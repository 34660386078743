/**用户路由 */
/** 首页路由 */
export default[
    {
        path:'customer',
        meta: {title: '商品'},
        name:'index',
        component: ()=>import('@/pages/customer/index.vue')
    }
]


