import VueCookies from 'vue-cookies'

// setToken
export const setCookies = (key, val, cookieExpires) => {
    VueCookies.set(key, val, { expires: cookieExpires || 1 })
}

// 获取getCookies
export const getCookies = (key) => {
    return VueCookies.get(key)
}

export const removeCookies = (key) => {
    return VueCookies.remove(key)
}
export const helperCopy = (url) => {
    let oInput = document.createElement('input');
    oInput.value = url;
    document.body.appendChild(oInput);
    oInput.select(); // 选择对象;
    // console.log(oInput.value)
    document.execCommand("Copy"); // 执行浏览器复制命令
    oInput.remove()
}